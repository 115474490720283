<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <b-card>
      <b-row>
        <b-col cols="12" md="12" xl="12">
          <b-card no-body class="invoice-preview-card table-responsive">
            <table class="table table-bordered">
              <thead class="thead-dark text-center">
                <tr>
                  <th scope="col" colspan="12">Calculated Formuls</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="(data, index) in calculatedArray" :key="index">
                  <th
                    colspan="9"
                    class="bg-light-info"
                    style="color:black!important;width:66%;"
                  >
                    {{ data.alias }}
                  </th>
                  <td style="width:33%;">
                    <b>{{ formatResult(data.result) }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <template #overlay v-if="loading">
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
        <h5 id="cancel-label">
          Formuls Calculating...
        </h5>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BPagination,
  BFormRating,
  BOverlay,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import axiosIns from "@/libs/axios";
import store from "@/store";
import router from "@/router";
import { onUnmounted } from "@vue/composition-api";
import formulStoreModule from "../formulStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, alpha } from "@validations";
import moment from "moment";
import _ from "lodash";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    BPagination,
    BFormRating,
    moment,
    vSelect,
    flatPickr,
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const FORMUL_APP_STORE_MODULE_NAME = "formul";
    // Register module
    if (!store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
      store.registerModule(FORMUL_APP_STORE_MODULE_NAME, formulStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
        store.unregisterModule(FORMUL_APP_STORE_MODULE_NAME);
    });
  },
  directives: {
    Ripple,
  },
  props: ["resultId"],
  data() {
    return {
      loading: false,
      selectedInput: null,
      formuls: [],
      calculatedArray: [],
      result: null,
    };
  },
  mounted() {
    //this.getSelectedInput(this.$route.params.id);
  },
  computed: {},
  methods: {
    getSelectedInput(id) {
      if (id > 0) {
        this.loading = true;

        this.$store
          .dispatch("formul/fetchInput", id)
          .then((res) => {
            this.selectedInput = res.data;
            this.getFormuls();
          })
          .catch((error) => {
            console.log("error get input data ", error);
          });
      }
    },
    getFormuls() {
      this.$store
        .dispatch("formul/fetchFormuls")
        .then((res) => {
          this.formuls = res.data;
          if (this.formuls.length > 0) {
            this.formuls.forEach((f) => {
              f.formula.forEach((formula) => {
                this.selectedInput.values.forEach((v) => {
                  if (v.id == formula.id) {
                    formula.value = v.value;
                  }
                });
              });
            });

            this.formuls.forEach((f) => {
              this.calculatedArray.push({
                alias: f.alias,
                result: this.formulas(f.formula),
              });
            });
            this.toastMessage("Data");
            this.loading = false;
          } else {
            this.noFormulaToastMessage();
          }
        })
        .catch((error) => {
          console.log("error get all formuls data ", error);
        });
    },
    formulas(formula) {
      switch (formula[1].operation) {
        case "*":
          this.result =
            this.calculateFormula(formula[0]) *
            this.calculateFormula(formula[2]);
          break;
        case "/":
          this.result =
            this.calculateFormula(formula[0]) /
            this.calculateFormula(formula[2]);
          break;
        case "+":
          // console.log(formula[0]);
          // console.log(formula[2]);
          this.result =
            this.calculateFormula(formula[0]) +
            this.calculateFormula(formula[2]);
          break;
        case "-":
          this.result =
            this.calculateFormula(formula[0]) -
            this.calculateFormula(formula[2]);
          break;
      }

      return this.result;
    },

    formulas2(formula) {
      switch (formula[1].operation) {
        case "*":
          return (
            this.calculateFormula(formula[0]) *
            this.calculateFormula(formula[2])
          );
        case "/":
          return (
            this.calculateFormula(formula[0]) /
            this.calculateFormula(formula[2])
          );
        case "+":
          return (
            this.calculateFormula(formula[0]) +
            this.calculateFormula(formula[2])
          );
        case "-":
          return (
            this.calculateFormula(formula[0]) -
            this.calculateFormula(formula[2])
          );
      }
    },

    calculateFormula(data) {
      if (data.category == "Numeric") {
        return data.operation;
      } else if (data.category == "Input") {
        return data.value;
      } else if (data.category == "Formula") {
        return this.formulas2(
          this.formuls.find((o) => o.id === data.id).formula
        );
      }
    },
    formatResult(value, val) {
      if (value != null) {
        // let val = (value / 1).toFixed(2).replace(',', '.');
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️ " + val + " Calculated Successful",
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    noFormulaToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: "No formula to calculate",
          icon: "AlertCircleIcon",
          variant: "warning",
        },
      });
    },
  },
  watch: {
    resultId: {
      handler: function(val, before) {
        if (val != 0 || val != undefined || val != null) {
          this.getSelectedInput(val);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
.label-font label {
  font-size: 1.2rem !important;
  font-weight: bold;
}
</style>
